import { call, put, take } from "redux-saga/effects";
import { IAction } from "../../schema";
import { AdminsHttp } from "../../services/http";
import {
  getSubscriptionAction,
  IGetSubscription,
  IGetSubscriptionArgs,
  updateSubscriptionAction,
  subscriptionConst,
  IUpdateSubscriptionArgs,
} from "../actions/subscription";

function* getSubscriptionHandler(args: IGetSubscriptionArgs) {
  try {
    yield put(getSubscriptionAction.loading());

    const resp: IGetSubscription = yield AdminsHttp({ token: args.token }).get(
      "/subscription/" + args.id
    );

    if (resp.type) {
      throw new Error(resp.error);
    }

    yield put(getSubscriptionAction.success(resp));
  } catch (e) {
    yield put(getSubscriptionAction.error(e.message));
  }
}

export function* watchGetSubscription() {
  while (true) {
    const { payload }: IAction<IGetSubscriptionArgs> = yield take(
      subscriptionConst.GET_SUBSCRIPTION
    );
    yield call(getSubscriptionHandler, payload);
  }
}

function* updateSubscriptionHandler(args: IUpdateSubscriptionArgs) {
  try {
    yield put(updateSubscriptionAction.loading());

    const resp: IGetSubscription = yield AdminsHttp({ token: args.token }).post(
      "/subscription/" + args.id,
      {},
      JSON.stringify({ limit_per_day: args.limit_per_day })
    );

    if (resp.type) {
      throw new Error(resp.error);
    }

    yield put(updateSubscriptionAction.success(resp));
  } catch (e) {
    yield put(updateSubscriptionAction.error(e.message));
  }
}

export function* watchUpdateSubscription() {
  while (true) {
    const { payload }: IAction<IUpdateSubscriptionArgs> = yield take(
      subscriptionConst.UPDATE_SUBSCRIPTION
    );
    yield call(updateSubscriptionHandler, payload);
  }
}
