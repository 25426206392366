import {
  IGetAccounts,
  accountsConst,
  IToggleAllowSendEmail,
  ISearchAccountInfo,
  IAccountCleanup,
} from "../actions/accounts";
import { IAction } from "../schema";
import { combineReducers } from "redux";

const getAccountsReducer = (
  state: IGetAccounts = {},
  action: IAction<IGetAccounts>
): IGetAccounts => {
  const { type, payload } = action;

  if (type === accountsConst.GET_ACCOUNTS) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        if (payload.data == null || payload.data.length === 0) {
          return {
            ...state,
            state: "success",
            is_first_page: payload.is_first_page,
            is_last_page: payload.is_last_page,
            has_data: false,
          };
        }

        return {
          ...state,
          state: "success",
          data: payload.data,
          prev_cursor: payload.prev_cursor,
          next_cursor: payload.next_cursor,
          is_first_page: payload.is_first_page,
          is_last_page: payload.is_last_page,
          has_data: true,
        };
      default:
        return state;
    }
  }

  return state;
};

const toggleAllowSendEmailReducer = (
  state: IToggleAllowSendEmail = {},
  action: IAction<IToggleAllowSendEmail>
): IToggleAllowSendEmail => {
  const { type, payload } = action;

  if (type === accountsConst.TOGGLE_ALLOW_SEND_EMAIL) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          state: "success",
          allow_send_email: payload.allow_send_email,
        };
      default:
        return state;
    }
  }

  return state;
};

const searchAccountInfoReducer = (
  state: ISearchAccountInfo = {},
  action: IAction<ISearchAccountInfo>
): ISearchAccountInfo => {
  const { type, payload } = action;

  if (type === accountsConst.SEARCH_ACCOUNT_INFO) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          state: "success",
          id: payload.id,
          domain_name: payload.domain_name,
          allow_send_email: payload.allow_send_email,
          is_domain_verified: payload.is_domain_verified,
          email: payload.email,
        };
      default:
        return state;
    }
  }

  return state;
};

const accountCleanupReducer = (
  state: IAccountCleanup = {},
  action: IAction<IAccountCleanup>
): IAccountCleanup => {
  const { type, payload } = action;

  if (type === accountsConst.DELETE_ACCOUNT_CLEANUP) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return { ...state, state: "success", deleted: payload.deleted };
      default:
        return state;
    }
  }

  return state;
};

export const rootAccountsReducer = combineReducers({
  Accounts: getAccountsReducer,
  AllowSendEmail: toggleAllowSendEmailReducer,
  SearchAccount: searchAccountInfoReducer,
  AccountCleanup: accountCleanupReducer,
});

export interface IRAccounts {
  Accounts: IGetAccounts;
  AllowSendEmail: IToggleAllowSendEmail;
  SearchAccount: ISearchAccountInfo;
  AccountCleanup: IAccountCleanup;
}
