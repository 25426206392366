import { createAction } from ".";
import { IAPIError } from "../../schema";

export const platformConst = {
  GET_ACTIVE_USERS: "GET_ACTIVE_USERS",
  GET_INSIGHTS: "GET_INSIGHTS",
};

export interface IActiveUsersData {
  aggregated_usage?: number;
  domain_names?: Array<string>;
  email?: string;
}

export interface IGetPlatformActiveUsers extends IAPIError {
  data?: Array<IActiveUsersData>;
  count?: number;
  total_pages?: number;
  per_page?: number;
}
export interface IGetPlatformActiveUsersArgs {
  token?: string;
  from: number;
  to: number;
  page: number;
}
export const getPlatformActiveUsersAction = createAction<
  IGetPlatformActiveUsers,
  IGetPlatformActiveUsersArgs
>(platformConst.GET_ACTIVE_USERS);

// Platform Insights
export interface IPlatformInsights {
  total_domains_registered?: number;
  total_domains_verified?: number;
  total_sent_count?: number;
  total_sent_count_by_account?: Record<string, number>;
  average_delivery_time?: number;
  pro_customer_count?: number;
  free_pro_customer_count?: number;
  delivery_time_by_day?: Array<IDeliveryTimeByDay>;
}

export interface IDeliveryTimeByDay {
  day?: number;
  average_time?: number;
  statics_by_provider?: Array<InsightsByProviders>;
}

export interface InsightsByProviders {
  provider?: string;
  count?: number;
  avg_delivery_time?: number;
}

export interface IGetPlatformInsights extends IPlatformInsights, IAPIError {}

export interface IGetPlatformInsightsArgs {
  token?: string;
  from: number;
  to: number;
  account_filters?: string;
}

export const getPlatformInsightsAction = createAction<
  IGetPlatformInsights,
  IGetPlatformInsightsArgs
>(platformConst.GET_INSIGHTS);
