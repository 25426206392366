import { call, put, take } from "redux-saga/effects";
import { IAction } from "../../schema";
import { AdminsHttp } from "../../services/http";
import {
  getPlatformActiveUsersAction,
  getPlatformInsightsAction,
  IGetPlatformActiveUsers,
  IGetPlatformActiveUsersArgs,
  IGetPlatformInsightsArgs,
  platformConst,
} from "../actions/platform";

function* getPlatformActiveUsersHandler(args: IGetPlatformActiveUsersArgs) {
  try {
    yield put(getPlatformActiveUsersAction.loading());

    const resp: IGetPlatformActiveUsers = yield AdminsHttp({
      token: args.token,
    }).get(
      `/platform/insights/active_users?from=${args.from}&to=${args.to}&page=${args.page}`
    );

    if (resp.type) {
      throw new Error(resp.error);
    }

    yield put(getPlatformActiveUsersAction.success(resp));
  } catch (e) {
    yield put(getPlatformActiveUsersAction.error(e.message));
  }
}

export function* watchGetPlatformActiveUsers() {
  while (true) {
    const { payload }: IAction<IGetPlatformActiveUsersArgs> = yield take(
      platformConst.GET_ACTIVE_USERS
    );
    yield call(getPlatformActiveUsersHandler, payload);
  }
}

function* getPlatformInsightsHandler(args: IGetPlatformInsightsArgs) {
  try {
    yield put(getPlatformInsightsAction.loading());

    let url = `/platform/insights?from=${args.from}&to=${args.to}`;
    if (args.account_filters) {
      url += `&account_filters=${args.account_filters}`;
    }
    const resp: IGetPlatformActiveUsers = yield AdminsHttp({
      token: args.token,
    }).get(url);

    if (resp.type) {
      throw new Error(resp.error);
    }

    yield put(getPlatformInsightsAction.success(resp));
  } catch (e) {
    yield put(getPlatformInsightsAction.error(e.message));
  }
}

export function* watchGetPlatformInsights() {
  while (true) {
    const { payload }: IAction<IGetPlatformInsightsArgs> = yield take(
      platformConst.GET_INSIGHTS
    );
    yield call(getPlatformInsightsHandler, payload);
  }
}
