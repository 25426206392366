import {
  IGetAccountsArgs,
  getAccountsAction,
  IGetAccounts,
  accountsConst,
  IToggleAllowSendEmailArgs,
  IToggleAllowSendEmail,
  ToggleAllowSendEmailAction,
  ISearchAccountArgs,
  getAccountInfoAction,
  ISearchAccountInfo,
  IAccountCleanupArgs,
  deleteAccountCleanupAction,
  IAccountCleanup,
} from "../actions/accounts";
import { put, take, call, select } from "redux-saga/effects";
import { AdminsHttp } from "src/services/http";
import { IAction } from "../../schema";
import { RootSchema } from "../reducers";

function* getAccountsHandler(args: IGetAccountsArgs) {
  try {
    let url = `/projects${
      args.next_cursor
        ? "?next_cursor=" + args.next_cursor
        : args.prev_cursor
        ? "?prev_cursor=" + args.prev_cursor
        : ""
    }`;

    yield put(getAccountsAction.loading());

    const resp: IGetAccounts = yield AdminsHttp({
      token: args.token,
    }).get(url);

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getAccountsAction.success(resp));
  } catch (e) {
    yield put(getAccountsAction.error(e.message));
  }
}

function* toggleAllowSendEmailHandler(args: IToggleAllowSendEmailArgs) {
  try {
    let url = "/project/send_email_permission/toggle";

    yield put(ToggleAllowSendEmailAction.loading());

    const resp: IToggleAllowSendEmail = yield AdminsHttp({
      token: args.token,
    }).post(
      url,
      {},
      JSON.stringify({
        id: args.id,
        allow_send_email: args.allow_send_email,
        domain: args.domain,
      })
    );

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(ToggleAllowSendEmailAction.success(resp));
  } catch (e) {
    yield put(ToggleAllowSendEmailAction.error(e.message));
  }
}

function* searchAccountInfoHandler(args: ISearchAccountArgs) {
  try {
    let url = `/search${
      args.email
        ? "?email=" + args.email
        : args.domain
        ? "?domain=" + args.domain
        : ""
    }`;

    yield put(getAccountInfoAction.loading());

    const resp: ISearchAccountInfo = yield AdminsHttp({
      token: args.token,
    }).get(url);

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getAccountInfoAction.success(resp));
  } catch (e) {
    yield put(getAccountInfoAction.error(e.message));
  }
}

function* accountCleanupHandler(args: IAccountCleanupArgs) {
  try {
    let url = `/accounts/${args.id}/clean_up`;

    yield put(deleteAccountCleanupAction.loading());

    const resp: IAccountCleanup = yield AdminsHttp({
      token: args.token,
    }).delete(url);

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(deleteAccountCleanupAction.success(resp));
  } catch (e) {
    yield put(deleteAccountCleanupAction.error(e.message));
  }
}

export function* watchGetAccounts() {
  while (true) {
    const { payload }: IAction<IGetAccountsArgs> = yield take(
      accountsConst.GET_ACCOUNTS
    );
    yield call(getAccountsHandler, payload);
  }
}

export function* watchToggleAllowSendEmail() {
  while (true) {
    const { payload }: IAction<IToggleAllowSendEmailArgs> = yield take(
      accountsConst.TOGGLE_ALLOW_SEND_EMAIL
    );
    yield call(toggleAllowSendEmailHandler, payload);
  }
}

export function* watchSearchAccountInfo() {
  while (true) {
    const { payload }: IAction<ISearchAccountArgs> = yield take(
      accountsConst.SEARCH_ACCOUNT_INFO
    );
    yield call(searchAccountInfoHandler, payload);
  }
}

export function* watchAccountCleanup() {
  while (true) {
    const { payload }: IAction<IAccountCleanupArgs> = yield take(
      accountsConst.DELETE_ACCOUNT_CLEANUP
    );
    yield call(accountCleanupHandler, payload);
  }
}
