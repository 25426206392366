/**
 * This file contains default App actions
 */

import { createAction } from ".";

export const defaultConst = {
  NETWORK_UPDATE: "NETWORK_UPDATE",
};

export interface INetwork {
  connected?: boolean;
}
export const updateNetworkAction = createAction<INetwork, INetwork>(
  defaultConst.NETWORK_UPDATE
);
