import { all } from "redux-saga/effects";
import { watchGetProfile } from "./user";
import {
  watchGetAccounts,
  watchToggleAllowSendEmail,
  watchSearchAccountInfo,
  watchAccountCleanup,
} from "./accounts";
import { watchGetSubscription, watchUpdateSubscription } from "./subscription";
import { watchGetUsageInsights } from "./insights";
import {
  watchGetPlatformActiveUsers,
  watchGetPlatformInsights,
} from "./platform";
import { watchGetProjectsByAccount } from "./project";

function* rootSaga() {
  yield all([
    // User
    watchGetProfile(),

    // Accounts
    watchGetAccounts(),
    watchToggleAllowSendEmail(),
    watchSearchAccountInfo(),
    watchGetSubscription(),
    watchUpdateSubscription(),
    watchAccountCleanup(),

    watchGetUsageInsights(),

    watchGetPlatformActiveUsers(),
    watchGetPlatformInsights(),

    watchGetProjectsByAccount(),
  ]);
}

export default rootSaga;
