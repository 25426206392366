import { createAction } from ".";
import { IAPIError } from "../../schema";

export const subscriptionConst = {
  GET_SUBSCRIPTION: "GET_SUBSCRIPTION",
  UPDATE_SUBSCRIPTION: "UPDATE_SUBSCRIPTION",
};

export interface ISubscription {
  limit_per_day?: number;
}

export interface IGetSubscription extends ISubscription, IAPIError {}
export interface IGetSubscriptionArgs {
  id: string;
  token: string;
}
export const getSubscriptionAction = createAction<
  IGetSubscription,
  IGetSubscriptionArgs
>(subscriptionConst.GET_SUBSCRIPTION);

export interface IUpdateSubscription extends ISubscription, IAPIError {}
export interface IUpdateSubscriptionArgs
  extends ISubscription,
    IGetSubscriptionArgs {}
export const updateSubscriptionAction = createAction<
  IUpdateSubscription,
  IUpdateSubscriptionArgs
>(subscriptionConst.UPDATE_SUBSCRIPTION);
