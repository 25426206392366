import React from "react";
import { RouteComponentProps, RouteProps, useLocation } from "react-router";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import LRObject from "../services/loginradius";
import { getCookie, removeCookie } from "../utils/urls";

// Navigations
const Auth = React.lazy(() => import("../containers/Auth"));
const UserProfile = React.lazy(() => import("../containers/UserProfile"));
const Dashboard = React.lazy(() => import("../containers/Dashboard"));
const ActiveUsers = React.lazy(() => import("../containers/ActiveUsers"));
const Insights = React.lazy(() => import("../containers/Insights"));

export const FALLBACK = (
  <div id="preloader">
    <div id="status">
      <div className="spinner">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
      </div>
    </div>
  </div>
);

export interface RoutesProps extends RouteComponentProps {}

class Routes extends React.Component<RoutesProps> {
  render() {
    return (
      <Switch>
        {/* Private Routes */}
        <ProtectedRoute
          exact
          path="/dashboard"
          render={() => (
            <React.Suspense fallback={FALLBACK}>
              <Dashboard />
            </React.Suspense>
          )}
        />

        <ProtectedRoute
          exact
          path="/active-users"
          render={() => (
            <React.Suspense fallback={FALLBACK}>
              <ActiveUsers />
            </React.Suspense>
          )}
        />

        <ProtectedRoute
          exact
          path="/profile"
          component={() => (
            <React.Suspense fallback={FALLBACK}>
              <UserProfile />
            </React.Suspense>
          )}
        />

        <ProtectedRoute
          exact
          path="/insights"
          component={() => (
            <React.Suspense fallback={FALLBACK}>
              <Insights />
            </React.Suspense>
          )}
        />

        {/* Public Routes */}
        <Route
          exact
          path="/login"
          render={() => (
            <React.Suspense fallback={FALLBACK}>
              <Auth type="login" />
            </React.Suspense>
          )}
        />
        <Route
          exact
          path="/forgotpassword"
          render={() => (
            <React.Suspense fallback={FALLBACK}>
              <Auth type="forgotpassword" />
            </React.Suspense>
          )}
        />
        <Route
          exact
          path="/resetpassword"
          render={() => (
            <React.Suspense fallback={FALLBACK}>
              <Auth type="resetpassword" />
            </React.Suspense>
          )}
        />
        <Route
          exact
          path="/logout"
          render={({ location }) => {
            const token = removeCookie("accessToken");
            LRObject.api.invalidateToken(token);
            const redirect =
              new URLSearchParams(location.search).get("redirect") ||
              "/dashboard";
            return <Redirect to={`/login?redirect=${redirect}`} />;
          }}
        />

        {/* Default route */}
        <Redirect to={"/login"} />
      </Switch>
    );
  }
}

export default withRouter(Routes);

// Functional Component
const ProtectedRoute: React.FC<RouteProps> = props => {
  const isLoggedIn = !!getCookie("accessToken");
  const { pathname, search } = useLocation();

  if (isLoggedIn) {
    return <Route {...props} />;
  } else return <Redirect to={`/login?redirect=${pathname + search}`} />;
};
