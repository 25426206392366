import { combineReducers } from "redux";
import { IAction } from "../../schema";
import { IGetUsageInsights, insightsConst } from "../actions/insights";

const getUsageInsightsReducer = (
  state: IGetUsageInsights = {},
  action: IAction<IGetUsageInsights>
): IGetUsageInsights => {
  const { type, payload } = action;

  if (type === insightsConst.GET_USAGE_INSIGHTS) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          state: "success",
          month_usage: payload.month_usage,
          today_usage: payload.today_usage,
        };
      default:
        return state;
    }
  }

  return state;
};

export const rootInsightsReducer = combineReducers({
  Insights: getUsageInsightsReducer,
});

export interface IRInsights {
  Insights: IGetUsageInsights;
}
