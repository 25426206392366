import * as React from "react";
import {
  Link,
  withRouter,
  RouteComponentProps,
  NavLink,
} from "react-router-dom";
import logoWhite from "../../assets/images/logo.svg";
import { Dropdown } from "semantic-ui-react";
import "./Navigation.scss";
import { RootSchema } from "../../redux/reducers";
import { connect } from "react-redux";
import { IGetProfile } from "../../redux/actions/user";

export interface NavigationProps extends RouteComponentProps {
  hidePaths?: string[];
  profile: IGetProfile;
}

export interface NavigationState {}

class Navigation extends React.Component<NavigationProps, NavigationState> {
  render() {
    const { hidePaths, location, profile } = this.props;
    if (hidePaths && hidePaths.includes(location.pathname)) {
      return null;
    }

    return (
      <header className="d-flex justify-content-between navigation">
        <nav>
          <ul className="d-flex align-items-stretch">
            <li className="d-flex align-items-stretch">
              <div className="logo d-flex align-items-center">
                <Link to="/dashboard">
                  <img src={logoWhite} alt="Mailazy" title="Mailazy" />
                </Link>
              </div>
            </li>
            <li className="d-flex align-items-stretch">
              <NavLink to="/dashboard" className="d-flex align-items-center">
                Dashboard
              </NavLink>
            </li>
            <li className="d-flex align-items-stretch">
              <NavLink to="/active-users" className="d-flex align-items-center">
                Active Users
              </NavLink>
            </li>
            <li className="d-flex align-items-stretch">
              <NavLink to="/insights" className="d-flex align-items-center">
                Insights
              </NavLink>
            </li>
          </ul>
        </nav>
        <nav>
          <ul className="d-flex align-items-stretch">
            <li className="d-flex align-items-center">
              Hi, {profile.FullName || "User"}!
            </li>
            <li className="d-flex align-items-stretch">
              <Dropdown
                icon="chevron down"
                text="My Account"
                className="d-flex align-items-center"
              >
                <Dropdown.Menu direction="left">
                  <Dropdown.Item
                    content={<Link to="/profile">Change Password</Link>}
                  />
                  <Dropdown.Item
                    onClick={() => this.props.history.push("/logout")}
                    content={<Link to="/logout">Logout</Link>}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

const mapState = (state: RootSchema) => {
  return {
    profile: state.user.profile,
  };
};

export default connect(mapState)(withRouter(Navigation));
