import { combineReducers } from "redux";
import { IAction } from "../../schema";
import { IGetProjectsByAccountId, projectConst } from "../actions/project";

const getProjectsByAccountReducer = (
  state: IGetProjectsByAccountId = {},
  action: IAction<IGetProjectsByAccountId>
): IGetProjectsByAccountId => {
  const { type, payload } = action;

  if (type === projectConst.GET_PROJECT_BY_ACCOUNT_ID) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success",
        };
      default:
        return state;
    }
  }

  return state;
};

export const rootProjectReducer = combineReducers({
  ProjectsByAccount: getProjectsByAccountReducer,
});

export interface IRProject {
  ProjectsByAccount: IGetProjectsByAccountId;
}
