import { combineReducers } from "redux";
import { IDefault, rootDefaultReducer } from "./default";
import { IUser, rootUserReducer } from "./user";
import { rootAccountsReducer, IRAccounts } from "./accounts";
import { rootSubscriptionReducer, IRSubscription } from "./subscription";
import { rootInsightsReducer, IRInsights } from "./insights";
import { IRPlatform, rootPlatformReducer } from "./platform";
import { IRProject, rootProjectReducer } from "./project";

// Combine all reducers
const rootReducer = combineReducers({
  default: rootDefaultReducer,
  user: rootUserReducer,
  accounts: rootAccountsReducer,
  subscription: rootSubscriptionReducer,
  insights: rootInsightsReducer,
  platform: rootPlatformReducer,
  projects: rootProjectReducer,
});

// Combine all schemas
export interface RootSchema {
  default: IDefault;
  user: IUser;
  accounts: IRAccounts;
  subscription: IRSubscription;
  insights: IRInsights;
  platform: IRPlatform;
  projects: IRProject;
}

export default rootReducer;
