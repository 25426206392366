import { call, put, take } from "redux-saga/effects";
import { IAction } from "../../schema";
import { AdminsHttp } from "../../services/http";
import {
  getProjectsByAccountAction,
  IGetProjectsByAccountId,
  IGetProjectsByAccountIdArgs,
  projectConst,
} from "../actions/project";

function* getProjectsByAccountHandler(args: IGetProjectsByAccountIdArgs) {
  try {
    yield put(getProjectsByAccountAction.loading());

    const resp: IGetProjectsByAccountId = yield AdminsHttp({
      token: args.token,
    }).get(`/account/${args.id}/projects`);

    if (resp.type) {
      throw new Error(resp.error);
    }

    yield put(getProjectsByAccountAction.success(resp));
  } catch (e) {
    yield put(getProjectsByAccountAction.error(e.message));
  }
}

export function* watchGetProjectsByAccount() {
  while (true) {
    const { payload }: IAction<IGetProjectsByAccountIdArgs> = yield take(
      projectConst.GET_PROJECT_BY_ACCOUNT_ID
    );
    yield call(getProjectsByAccountHandler, payload);
  }
}
