import { createAction } from ".";
import { IAPIError } from "../../schema";

export const insightsConst = {
  GET_USAGE_INSIGHTS: "GET_USAGE_INSIGHTS",
};

export interface IUsageInsights {
  today_usage?: number;
  month_usage?: number;
}

export interface IGetUsageInsights extends IUsageInsights, IAPIError {}
export interface IGetUsageInsightsArgs {
  id: string;
  token: string;
}
export const getUsageInsightsAction = createAction<
  IGetUsageInsights,
  IGetUsageInsightsArgs
>(insightsConst.GET_USAGE_INSIGHTS);
