import { IAPIError } from "../../schema";
import { createAction } from ".";

export const projectConst = {
  GET_PROJECT_BY_ACCOUNT_ID: "GET_PROJECT_BY_ACCOUNT_ID",
};

export interface IGetProjectsByAccountId extends IAPIError {
  data?: Array<IProject>;
  email?: string;
}

export interface IProject {
  account_id?: string;
  created_at?: Date;
  id?: string;
  name?: string;
  sender_domains?: Array<IProjectSenderDomain>;
}

export interface IProjectSenderDomain {
  domain_name?: string;
  is_domain_verified?: boolean;
  allow_send_email?: boolean;
}

export interface IGetProjectsByAccountIdArgs {
  token?: string;
  id?: string;
}

export const getProjectsByAccountAction = createAction<
  IGetProjectsByAccountId,
  IGetProjectsByAccountIdArgs
>(projectConst.GET_PROJECT_BY_ACCOUNT_ID);
