function getConfig() {
  return {
    idp_apikey:
      process.env.REACT_APP_IDP_KEY || "4bc2842a-9336-4641-8aef-6056d66395fe",
    idp_endpoint:
      process.env.REACT_APP_IDP_ENDPOINT || "https://api.loginradius.com",
    idp_hub_endpoint:
      process.env.REACT_APP_HUB_ENDPOINT ||
      "https://mailazy.hub.loginradius.com/requesthandler.aspx",
    idp_sott:
      process.env.REACT_APP_IDP_SOTT ||
      "pFWFBl+LUmP3oNao90iiCbDxkJgaRBo1zbq2nl6MGJGwrHQuj5nMxJmePEio1Gznv27XGTWXOkWOo5arEtbzto5W3oguoAREAFZvsczNCUM=*1e9625583e8b14faa072b5359a3691a3",
    api_endpoint:
      process.env.REACT_APP_API_ENDPOINT || "http://devconsoleapi.mailazy.com/v1",
    domain_verification_secret:
      process.env.REACT_APP_DOMAIN_VERIFICATION_SECRET ||
      "_dLHb4tbgbFDKxsCaiiaRyBFz3scz6CXGira57Mho",
    insights_account_filters:
      "2c48e3e9d7d84ccd89047302d424dcc3:loginradi.us:loginradius.com:demotesting.lrinternal.com:accounts.loginradius.com,2b2711af5f1a421386d8d2bb3b352c05:mojoauth.com",
  };
}

export default getConfig();
