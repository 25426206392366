import { combineReducers } from "redux";
import { IAction } from "../../schema";
import {
  IGetPlatformActiveUsers,
  IGetPlatformInsights,
  platformConst,
} from "../actions/platform";

const getPlatformActiveUsersReducer = (
  state: IGetPlatformActiveUsers = {},
  action: IAction<IGetPlatformActiveUsers>
): IGetPlatformActiveUsers => {
  const { type, payload } = action;

  if (type === platformConst.GET_ACTIVE_USERS) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success",
        };
      default:
        return state;
    }
  }

  return state;
};

const getPlatformInsightsReducer = (
  state: IGetPlatformInsights = {},
  action: IAction<IGetPlatformInsights>
): IGetPlatformInsights => {
  const { type, payload } = action;

  if (type === platformConst.GET_INSIGHTS) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success",
        };
      default:
        return state;
    }
  }

  return state;
};

export const rootPlatformReducer = combineReducers({
  PlatformActiveUsers: getPlatformActiveUsersReducer,
  PlatformInsights: getPlatformInsightsReducer,
});

export interface IRPlatform {
  PlatformActiveUsers: IGetPlatformActiveUsers;
  PlatformInsights: IGetPlatformInsights;
}
