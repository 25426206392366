import { combineReducers } from "redux";
import { IAction } from "../../schema";
import {
  subscriptionConst,
  IGetSubscription,
  IUpdateSubscription,
} from "../actions/subscription";

const getSubscriptionReducer = (
  state: IGetSubscription = {},
  action: IAction<IGetSubscription>
): IGetSubscription => {
  const { type, payload } = action;

  if (type === subscriptionConst.GET_SUBSCRIPTION) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          state: "success",
          limit_per_day: payload.limit_per_day,
        };
      default:
        return state;
    }
  }

  return state;
};

const updateSubscriptionReducer = (
  state: IUpdateSubscription = {},
  action: IAction<IUpdateSubscription>
): IUpdateSubscription => {
  const { type, payload } = action;

  if (type === subscriptionConst.UPDATE_SUBSCRIPTION) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          state: "success",
          limit_per_day: payload.limit_per_day,
        };
      default:
        return state;
    }
  }

  return state;
};

export const rootSubscriptionReducer = combineReducers({
  Subscription: getSubscriptionReducer,
  UpdateSubscription: updateSubscriptionReducer,
});

export interface IRSubscription {
  Subscription: IGetSubscription;
  UpdateSubscription: IUpdateSubscription;
}
