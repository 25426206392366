import { call, put, take } from "redux-saga/effects";
import { IAction } from "../../schema";
import { AdminsHttp } from "../../services/http";
import {
  IGetUsageInsights,
  getUsageInsightsAction,
  IGetUsageInsightsArgs,
  insightsConst,
} from "../actions/insights";

function* getUsageInsightsHandler(args: IGetUsageInsightsArgs) {
  try {
    yield put(getUsageInsightsAction.loading());

    const resp: IGetUsageInsights = yield AdminsHttp({ token: args.token }).get(
      "/insights/usage/" + args.id
    );

    if (resp.type) {
      throw new Error(resp.error);
    }

    yield put(getUsageInsightsAction.success(resp));
  } catch (e) {
    yield put(getUsageInsightsAction.error(e.message));
  }
}

export function* watchGetUsageInsights() {
  while (true) {
    const { payload }: IAction<IGetUsageInsightsArgs> = yield take(
      insightsConst.GET_USAGE_INSIGHTS
    );
    yield call(getUsageInsightsHandler, payload);
  }
}
