import { IAPIError } from "../../schema";
import { createAction } from ".";

export const accountsConst = {
  GET_ACCOUNTS: "GET_ACCOUNTS",
  TOGGLE_ALLOW_SEND_EMAIL: "TOGGLE_ALLOW_SEND_EMAIL",
  SEARCH_ACCOUNT_INFO: "SEARCH_ACCOUNT_INFO",
  DELETE_ACCOUNT_CLEANUP: "DELETE_ACCOUNT_CLEANUP",
};

export interface IGetAccountsArgs {
  token: string;
  prev_cursor?: string;
  next_cursor?: string;
}

export interface Accounts {
  id: string;
  account_id: string;
  name: string;
  email: string;
  created_at: string;
  sender_domains?: Array<SenderDomains>;
}

export interface SenderDomains {
  domain_name: string;
  allow_send_email: boolean;
  is_domain_verified: boolean;
}

interface GetAccountsResp {
  data?: Array<Accounts>;
  prev_cursor?: string;
  next_cursor?: string;
  is_first_page?: boolean;
  is_last_page?: boolean;
  has_data?: boolean;
}

export interface IGetAccounts extends GetAccountsResp, IAPIError {}

export const getAccountsAction = createAction<IGetAccounts, IGetAccountsArgs>(
  accountsConst.GET_ACCOUNTS
);

export interface IToggleAllowSendEmailArgs {
  token: string;
  id?: string;
  allow_send_email: boolean;
  domain?: string;
}

export interface ToggleAllowSendEmailResp {
  allow_send_email?: boolean;
}

export interface IToggleAllowSendEmail
  extends ToggleAllowSendEmailResp,
    IAPIError {}

export const ToggleAllowSendEmailAction = createAction<
  IToggleAllowSendEmail,
  IToggleAllowSendEmailArgs
>(accountsConst.TOGGLE_ALLOW_SEND_EMAIL);

// Search Account Action
export interface ISearchAccountArgs {
  token?: string;
  email: string;
  domain: string;
}

export interface ISearchAccount {
  id?: string;
  domain_name?: string;
  allow_send_email?: boolean;
  is_domain_verified?: boolean;
  email?: string;
  created_at?: string;
}

export interface ISearchAccountInfo extends ISearchAccount, IAPIError {}
export const getAccountInfoAction = createAction<
  ISearchAccountInfo,
  ISearchAccountArgs
>(accountsConst.SEARCH_ACCOUNT_INFO);

// Account DNS Cleanup
export interface IAccountCleanupArgs {
  token?: string;
  id?: string;
}
export interface IAccountCleanup extends IAPIError {
  deleted?: string;
}
export const deleteAccountCleanupAction = createAction<
  IAccountCleanup,
  IAccountCleanupArgs
>(accountsConst.DELETE_ACCOUNT_CLEANUP);
